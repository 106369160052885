import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Youtube, { YouTubeProps } from 'react-youtube';
import { routes } from "../../../../routes/routes";
import { Trans } from "react-i18next";
import { outLinks } from "../../../../routes/routes";
import { BigglzContext } from "../../../../utils/contextProvider";
import { ENG, KOR } from "../../../../layout/layout";

export const ServiceTitTxt = () => {
  const { myLanguage } = useContext(BigglzContext);

  const opts = {
    width: '100%',
    height: '100%',
  }

  return (
    <div className="video-area">
      <div className="video-wrap">
        <div className="iframe-wrap">
          <Youtube
            videoId={ 'EfFq5zQBEZo' }
            opts={ opts }
          />
        </div>
        
      </div>
      {
        myLanguage === KOR && (
          <div className="txt">
            <div className="tit">나를 이해해주고 위로해주는 대화</div>
            <div className="desc">
            AI가 나의 대화를 공감해주는 세상을 경험해보세요. 비글즈의 언어 모델은 진심 어린 응원과 정성스러운 반응을 통해 따뜻한 마음을 전달하여 모든이가 건강한 마인드를 가질 수 있도록 도와줄거예요.
            </div>
          </div>
        )
      }
      {
        myLanguage === ENG && (
          <div className="txt">
            <div className="tit">Find Comfort in Conversations That Understand You.</div>
            <div className="desc">
              Immerse yourself in a world where AI meets empathy. Our advanced language model offers heartfelt support, guiding you toward greater mental well-being with every interaction.
            </div>
          </div>
        )
      }
    </div>
  )
}
// export const ServiceTitTxt = () => {
//   const { myLanguage } = useContext(BigglzContext);

//   return (
//     <>
//       <h3>
//         {
//           myLanguage === KOR ? (
//             <>
//               우리는 모두가 위로받고
//               <span>
//                 <strong>힐링할 수 있는 세상</strong>을
//               </span>
//               <span>열어가요</span>
//             </>
//           ) : myLanguage === ENG ? (
//             <>
//               We are opening a world
//               <span>where everyone can receive</span>
//               <span>
//                 <strong>comfort and healing.</strong>
//               </span>
//             </>
//           ) : null
//         }
//       </h3>
//       <p>
//         {
//           myLanguage === KOR ? (
//             <>
//               비글즈는 귀여운 캐릭터와 함께하는 미래를 꿈꾸고 있어요.
//               <span> 깜찍한 가상펫을 기를 수 있는 비글즈 앱을 비롯한 굿즈와 영상 콘텐츠, AI 챗봇 등</span>
//               <span> 비글즈와 크리에이터, 그리고 똑똑한 개발자들과 함께 무한한 가능성을 제공해요.</span>
//               <span> 사람들에게 따뜻한 감성이 전달되어 힐링할 수 있도록 새로운 시장을 열어가고자 해요.</span>
//             </>
//           ) : myLanguage === ENG ? (
//             <>
//               Bigglz is dreaming of a future with cute characters.
//               <span> We provide endless possibilities with creators and smart developers who create Bigglz apps</span>
//               <span> where you can raise cute virtual pets, goods, video content, AI chatbots, etc..</span>
//               <span> Create a world with warm emotions and provide healing.</span>
//             </>
//           ) : null
//         }
//       </p>
//     </>
//   );
// }

export const CardInfo = () => {
  const { myLanguage, headerRef } = useContext(BigglzContext);
  const cardWrapRef = useRef(null);
  const listRef = useRef(null);
  const [categoriChangeView, setCategoriChangeView] = useState("");
  const [scrollView, setScrollView] = useState({
    ref0: false,
    ref1: false,
    ref2: false,
    ref3: false,
    ref4: false,
  });
  const categoryRefs = {
    hiingAppRef: useRef(null),
    bigglzAppRef: useRef(null),
    characterRef: useRef(null),
    cafeRef: useRef(null),
    galleryRef: useRef(null),
  }
  const refsArr = Object.values(categoryRefs);

  useEffect(() => {
    /** 서비스 카테고리 */
    const categoryScrolled = () => {
      const updateCategoryIdx = {
        ref0: refsArr[0].current.getBoundingClientRect().top - headerRef.current.clientHeight <= 0,
        ref1: refsArr[1].current.getBoundingClientRect().top - headerRef.current.clientHeight <= 0,
        ref2: refsArr[2].current.getBoundingClientRect().top - headerRef.current.clientHeight <= 0,
        ref3: refsArr[3].current.getBoundingClientRect().top - headerRef.current.clientHeight <= 0,
        ref4: refsArr[4].current.getBoundingClientRect().top - headerRef.current.clientHeight <= 0
      }

      let lastKey = null;
      for (let key in updateCategoryIdx) {
        if (updateCategoryIdx[key] === true) {
          lastKey = key;
        }
        updateCategoryIdx[key] = false;
      }
      updateCategoryIdx[lastKey] = true;

      if (lastKey === null) {
        updateCategoryIdx["ref0"] = true;
      }
      setScrollView(updateCategoryIdx);
    }

    /** 스크롤 시 특정 위치에서 리스트 고정 이벤트 */
    const listFixedScroll = () => {
      if ((cardWrapRef.current.getBoundingClientRect().top - headerRef.current.clientHeight + 56) <= 0) {
        setCategoriChangeView("fixed");
      } else {
        setCategoriChangeView("");
      }

      // 모바일에서 visual 고정으로 인한 top 변경
      if (window.innerWidth <= 1080) {
        if ((cardWrapRef.current.getBoundingClientRect().top - headerRef.current.clientHeight) <= 0) {
          setCategoriChangeView("fixed");
        }
      }
    }

    /** 카테고리 스크롤 이벤트 */
    const handleCategoryScroll = () => {
      listFixedScroll();
      categoryScrolled();
    }

    window.addEventListener("scroll", handleCategoryScroll);

    return () => {
      window.removeEventListener("scroll", handleCategoryScroll);
    }
  }, [headerRef, refsArr]);

  /** 카테고리 클릭 이벤트 */
  const handleClickScrolling = (idx) => {
    const scrollYset = refsArr[idx].current.getBoundingClientRect().top + window.pageYOffset - headerRef.current.clientHeight + 1;
    window.scrollTo({ top: scrollYset });
  }

  return (
    <div>
      <div className="title">
        {
          myLanguage === KOR ? '비글즈 사업 소개' : 'Bigglz’s Business Fields'
        }
        
      </div>
      <ul className={`list ${categoriChangeView}`} ref={listRef}>
        <li className={scrollView.ref0 ? "on" : ""}>
          <Link to='#' onClick={() => handleClickScrolling(0)}>
            <Trans i18nKey="service.categori1" />
          </Link>
        </li>
        <li className={scrollView.ref1 ? "on" : ""}>
          <Link to='#' onClick={() => handleClickScrolling(1)}>
            <Trans i18nKey="service.categori2" />
          </Link>
        </li>
        <li className={scrollView.ref2 ? "on" : ""}>
          <Link to='#' onClick={() => handleClickScrolling(2)}>
            <Trans i18nKey="service.categori3" />
          </Link>
        </li>
        <li className={scrollView.ref3 ? "on" : ""}>
          <Link to='#' onClick={() => handleClickScrolling(3)}>
            <Trans i18nKey="service.categori4" />
          </Link>
        </li>
        {/* <li className={scrollView.ref4 ? "on" : ""}>
          <Link to='#' onClick={() => handleClickScrolling(4)}>
            <Trans i18nKey="service.categori5" />
          </Link>
        </li> */}
      </ul>
      <div className='card-wrap' ref={cardWrapRef}>
        <div className='card-box app first' ref={refsArr[0]}> {/* 하잉 앱 */}
          <div className='card-area'>
            {/* <div className="tit">Application</div> */}
            <div className='card'>
              <div className="app-info hiing">
                <div className='front'>
                  <div className="top">
                    <figure>
                      <img src='/images/sub/ic_hiing.png' alt='비글즈 가상펫 양육 어플리케이션' />
                    </figure>
                    <dl className="dl-service">
                      {
                        myLanguage === KOR ? (
                          <>
                            <dt>하잉 앱</dt>
                            <dd>나만의 특별한 친구와 함께하는 힐링 대화앱</dd>
                          </>
                        ) : myLanguage === ENG ? (
                          <>
                            <dt>Hiing App</dt>
                            <dd>Have healing conversation with your own special friend</dd>
                          </>
                        ) : null
                      }
                    </dl>
                  </div>
                  
                  <div className="app-download">
                    <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                      <img src="/images/eng/eng_btn_appstore.png" alt="Download on the App Store" />
                    </a>
                    <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                      <img src="/images/eng/eng_btn_googleplay.png" alt="Get it on Google Play" />
                    </a>
                  </div>
                </div>
                <div className="info-area">
                  <ul>
                    {
                      myLanguage === KOR ? (
                        <>
                          <li>
                            마음 편하게 대화할 수 있도록 <span>#경청해주는 친구</span>
                          </li>
                          <li>
                            내 기분과 감정을 살펴주는 <span>#따뜻함</span>
                          </li>
                          <li>
                            모든 이야기를 <span>#공감해주는 친구</span>
                          </li>
                          <li>
                            나와 했던 대화를 기억하고 안부를 물어봐주는 <span>#자상함</span>
                          </li>
                        </>
                      ) : myLanguage === ENG ? (
                        <>
                          <li>
                            A friend who <span>#listens</span> so you can talk comfortably
                          </li>
                          <li>
                            <span>#Warmth</span> that takes care of your feelings and emotions
                          </li>
                          <li>
                            A friend who <span>#sympathizes</span> with your story
                          </li>
                          <li>
                            <span>#Kindness</span> of remembering all conversation you had and asking how you was doing.
                          </li>
                        </>
                      ) : null
                    }
                  </ul>
                  <div className="ces-icon"><img src="/images/sub/ces_Logo.png" alt="" /></div>
                  <div className="mo-app-download">
                    <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                      <img src="/images/eng/eng_btn_appstore.png" alt="Download on the App Store" />
                    </a>
                    <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                      <img src="/images/eng/eng_btn_googleplay.png" alt="Get it on Google Play" />
                    </a>
                  </div>
                  <div className="button">
                    <button className="hiing">
                      <Link to={ routes.persona }>
                        <span>model detail</span>
                        <div className="icon"><img src="/images/sub/ic_arrow.png" alt="" /></div>
                      </Link>
                    </button>
                  </div>
                  {/* <div className="btn-area">
                    {
                      myLanguage === KOR ? (
                        <>
                          <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                            <img src="/images/common/btn_appstore.png" alt="app store 에서 다운로드 하기" />
                          </a>
                          <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                            <img src="/images/common/btn_googleplay.png" alt="google play에서 다운로드 하기" />
                          </a>
                        </>
                      ) : myLanguage === ENG ? (
                        <>
                          <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                            <img src="/images/eng/eng_btn_appstore.png" alt="Download on the App Store" />
                          </a>
                          <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                            <img src="/images/eng/eng_btn_googleplay.png" alt="Get it on Google Play" />
                          </a>
                        </>
                      ) : null
                    }
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-box app' ref={refsArr[1]}> {/* 비글즈 앱 */}
          <div className='card-area'>
            {/* <div className="tit">Application</div> */}
            <div className='card'>
              <div className="app-info bigglz">
                <div className='front'>
                  <div className="top">
                    <figure>
                      {
                        myLanguage === KOR ? (
                          <>
                            <img src='/images/sub/ic_app01.png' alt='bigglz app' />
                          </>
                        ) : myLanguage === ENG ? (
                          <>
                            <img src='/images/eng/eng_ic_app01.png' alt='bigglz app' />
                          </>
                        ) : null
                      }
                    </figure>
                    <dl className="dl-service">
                      {
                        myLanguage === KOR ? (
                          <>
                            <dt>비글즈 앱</dt>
                            <dd>가상펫 양육 어플리케이션</dd>
                          </>
                        ) : myLanguage === ENG ? (
                          <>
                            <dt>Bigglz App</dt>
                            <dd>Virtual pet raising application</dd>
                          </>
                        ) : null
                      }
                    </dl>
                  </div>
                  <div className="app-download">
                    <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                      <img src="/images/eng/eng_btn_appstore.png" alt="Download on the App Store" />
                    </a>
                    <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                      <img src="/images/eng/eng_btn_googleplay.png" alt="Get it on Google Play" />
                    </a>
                  </div>
                </div>
                <div className="info-area">
                  <ul>
                    {
                      myLanguage === KOR ? (
                        <>
                          <li>
                            나만의 펫과 함께 꾸미는 <span>#펫룸</span>
                          </li>
                          <li>
                            펫과 함께 대화할 수 있는 <span>#챗봇</span>
                          </li>
                          <li>
                            나의 캘린더를 풍성하게 해주는 <span>#다이어리</span>
                          </li>
                          <li>
                            펫과 나의 일상을 공유하는 <span>#펫스타그램</span>
                          </li>
                          <li>
                            친구와 감성 넘치는 편지를 주고 받는 <span>#펫팔로우</span>
                          </li>
                          <li>
                            친구들과 소통하고 게임하는 <span>#테마월드</span>
                          </li>
                        </>
                      ) : myLanguage === ENG ? (
                        <>
                          <li>
                            that allows you to talk with your pet <span>#Petroom</span>
                          </li>
                          <li>
                            That allows you to talk with your pet <span>#Chatbot</span>
                          </li>
                          <li>
                            That enriches my calendar <span>#Diary</span>
                          </li>
                          <li>
                            To share your daily life with your pet <span>#Petstagram</span>
                          </li>
                          <li>
                            Exchanges / emotional letters with friends <span>#PetFollow</span>
                          </li>
                          <li>
                            Where you can communicate and play games with friends <span>#ThemeWorld</span>
                          </li>
                        </>
                      ) : null
                    }
                  </ul>
                  <div className="ces-icon"><img src="/images/sub/ces_Logo.png" alt="" /></div>
                  <div className="mo-app-download">
                    <a href={outLinks.hiingAppStore} target='_blank' rel="noopener noreferrer" className="btn apple">
                      <img src="/images/eng/eng_btn_appstore.png" alt="Download on the App Store" />
                    </a>
                    <a href={outLinks.hiingGoogleStore} target='_blank' rel="noopener noreferrer" className="btn google">
                      <img src="/images/eng/eng_btn_googleplay.png" alt="Get it on Google Play" />
                    </a>
                  </div>
                  <div className="button">
                    <button className="bigglz">
                      <Link to={ routes.home }>
                        <span>model detail</span>
                        <div className="icon"><img src="/images/sub/ic_arrow.png" alt="" /></div>
                      </Link>
                    </button>
                  </div>
                </div>
              </div>      
            </div>
          </div>
        </div>
        <div className='card-box between character' ref={refsArr[2]}>
          <div className='card-area'>
            {/* <div className="tit">Character IP</div> */}
            <div className='card'>
              {
                myLanguage === KOR ? (
                  <>
                    <div className='front'>
                      <figure>
                        <img src='/images/eng/en_service_character_ip.png' alt='캐릭터 비즈니스 비글즈의 캐릭터들' />
                      </figure>
                      {/* <dl className="dl-service">
                        <dt>캐릭터 비즈니스</dt>
                        <dd>비글즈의 캐릭터들</dd>
                      </dl> */}
                    </div>
                  </>
                ) : myLanguage === ENG ? (
                  <>
                    <div className='front'>
                      <figure>
                        <img src='/images/eng/en_service_character_ip.png' alt='캐릭터 비즈니스 비글즈의 캐릭터들' />
                      </figure>
                      {/* <dl className="dl-service">
                        <dt>Character business</dt>
                        <dd>Bigglz's IP</dd>
                      </dl> */}
                    </div>
                  </>
                ) : null
              }
            </div>
            <div className="card-info">
              <dl>
                {
                  myLanguage === KOR ? (
                    <>
                      <dt>캐릭터 비즈니스</dt>
                      <dd className='highlight'>펫팔파이브 캐릭터를 기반으로 한 다양한 사업 확장</dd>
                      <dd>펫팔파이브 캐릭터 기반 다양한 영상 컨텐츠</dd>
                      <dd>캐릭터 활용 교육용 컨텐츠, 툰 컨텐츠</dd>
                      <dd>감성 캐릭터 작가들과의 콜라보 사업</dd>
                      <dd>캐릭터 라이선스 사업</dd>
                      <dd>의류 및 다양한 굿즈 제작</dd>
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <dt>Character IP</dt>
                      <dd className='highlight'>Various business expansions based on our own characters : called “petpal five” & “Pogny”</dd>
                      <dd>Plenty of contents based on the characters of petpal five</dd>
                      <dd>Education contents and toon contents based on the characters</dd>
                      <dd>Collaborating with character creators</dd>
                      <dd>Character license business</dd>
                      <dd>Clothing and goods production</dd>
                    </>
                  ) : null
                }
              </dl>
              <div className="btn-area social">
                {
                  myLanguage === KOR ? (
                    <>
                      <a
                        href={outLinks.bigglzPetpal}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn instagram"
                      >
                        <i></i>인스타그램 바로가기
                      </a>
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <a
                        href={outLinks.bigglzPetpal}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn instagram"
                      >
                        <i></i>Instagram
                      </a>
                    </>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className='card-box between cafe' ref={refsArr[3]}>
          <div className='card-area'>
            {/* <div className="tit">Cafe</div> */}
            <div className="card">
              {
                myLanguage === KOR ? (
                  <>
                    <div className='front'>
                      <figure>
                        <img src='/images/eng/en_service_cafe.png' alt='카페GG 아기자기한 감성이 담긴 카페' />
                      </figure>
                      {/* <dl className="dl-service">
                        <dt>Cafe gg</dt>
                        <dd>아기자기한 감성이 담긴 카페</dd>
                      </dl> */}
                    </div>
                  </>
                ) : myLanguage === ENG ? (
                  <>
                    <div className='front'>
                      <figure>
                        <img src='/images/eng/en_service_cafe.png' alt='카페GG 아기자기한 감성이 담긴 카페' />
                      </figure>
                      {/* <dl className="dl-service">
                        <dt>Cafe gg</dt>
                        <dd>A cafe with cute sentiments</dd>
                      </dl> */}
                    </div>
                  </>
                ) : null
              }
            </div>
            <div className="card-info">
              <dl>
                {
                  myLanguage === KOR ? (
                    <>
                      <dt>
                        카페
                        <span>
                          <img src='/images/sub/ic_gg.png' alt='GG' />
                        </span>
                      </dt>
                      <dd className='highlight'>최고의 디저트를 제공하는 베이커리 카페</dd>
                      <dd>gg쉐프님들이 직접 만든 다양한 빵과 커피</dd>
                      <dd>200평이 넘는 넓-은 실내</dd>
                      <dd>감성 충만한 포토존</dd>
                      <dd>아기자기한 굿즈들이 가득</dd>
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <dt>
                        Cafe
                        <span className="icon-image">
                          <img src='/images/sub/ic_gg.png' alt='GG' />
                        </span>
                      </dt>
                      <dd className='highlight'>Bakery cafe with the best dessert</dd>
                      <dd>gg chefs&#39; hand made breads and coffees</dd>
                      <dd>Space over 7,000 square ft </dd>
                      <dd>Photo zone with full of emotion</dd>
                      <dd>Full of charming goods</dd>
                    </>
                  ) : null
                }
              </dl>
              <div className="btn-area social">
                {
                  myLanguage === KOR ? (
                    <>
                      <a href={outLinks.ggisland} target="_blank" rel="noopener noreferrer" className="btn instagram"><i></i>인스타그램 바로가기</a>
                      <a href={outLinks.ggPlacPath} target="_blank" rel="noopener noreferrer" className="btn naver"><i></i>네이버 맵 바로가기</a>
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <a href={outLinks.ggisland} target="_blank" rel="noopener noreferrer" className="btn instagram"><i></i>Instagram</a>
                      <a href={outLinks.ggPlacPath} target="_blank" rel="noopener noreferrer" className="btn naver"><i></i>Naver Map</a>
                    </>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className='card-box between gallery' ref={refsArr[4]}> {/* 갤러리 */}
          <div className='card-area'>
            <div className="tit">Art gg Gallery</div>
            <div className="card">
              {
                myLanguage === KOR ? (
                  <>
                    <div className='front'>
                      <figure>
                        <img src='/images/eng/en_service_gallery gg.png' alt='아트 GG 갤러리 유명한 작가님들의 전시공간' />
                      </figure>
                      {/* <dl className="dl-service">
                        <dt>Art gg Gallary</dt>
                        <dd>유망한 작가님들의 전시공간</dd>
                      </dl> */}
                    </div>
                  </>
                ) : myLanguage === ENG ? (
                  <>
                    <div className='front'>
                      <figure>
                        <img src='/images/eng/en_service_gallery gg.png' alt='아트 GG 갤러리 유명한 작가님들의 전시공간' />
                      </figure>
                      {/* <dl className="dl-service">
                        <dt>Art gg Gallary</dt>
                        <dd>An exhibition space of promising artists</dd>
                      </dl> */}
                    </div>
                  </>
                ) : null
              }
            </div>
            <div className="card-info">
              <dl>
                {
                  myLanguage === KOR ? (
                    <>
                      <dt>
                        아트
                        <span>
                          <img src='/images/sub/ic_gg.png' alt='GG' />
                        </span>
                        갤러리
                      </dt>
                      <dd className='highlight'>미술계의 유망한 작가님들의 작품이 전시되는 공간</dd>
                      <dd>활발하게 활동하고 있는 작가님들의 작품전시</dd>
                      <dd>유망한 신진작가 발굴</dd>
                      <dd>작품 전시 및 판매</dd>
                      <dd>아기자기한 굿즈들이 가득</dd>
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <dt>
                        Art
                        <span className="icon-image">
                          <img src='/images/sub/ic_gg.png' alt='GG' />
                        </span>
                        gallary
                      </dt>
                      <dd className='highlight'>Space where well known artists&#39; works in the art world are displayed</dd>
                      <dd>Exhibition of active artists</dd>
                      <dd>Discovering future promising artists</dd>
                      <dd>Arts exhibition and sale</dd>
                      <dd>Full of charming goods</dd>
                    </>
                  ) : null
                }
              </dl>
              <div className="btn-area social">
                {
                  myLanguage === KOR ? (
                    <>
                      <a
                        href={outLinks.gallery}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn gallery"
                      >
                        <i></i>아트gg갤러리 바로가기
                      </a>
                    </>
                  ) : myLanguage === ENG ? (
                    <>
                      <a
                        href={outLinks.gallery}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn gallery"
                      >
                        <i></i>Art gg gallery
                      </a>
                    </>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}